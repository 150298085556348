import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.scss";
import "./vars.less";
import { Button } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  withRouter,
} from "react-router-dom";
import MainView from "./Pages/Main/MainView";
import "leaflet/dist/leaflet.css";
import Login from "./Pages/Login/Login";
import { Provider } from "react-redux";
import store from "./store";
import Axios from "axios";

Axios.interceptors.request.use(function (config) {
  // console.log(config);
  // config.headers["Content-Type"] = "application/json";
  // config.headers["Access-Control-Allow-Methods"] =
  //   "GET, POST, PATCH, PUT, DELETE, OPTIONS";

  return config;
});

// eslint-disable-next-line no-extend-native
Number.prototype.toInterval = function () {
  let sec = this;
  const h = parseInt(sec / 3600);
  if (h > 0) {
    sec -= h * 3600;
  }
  const m = parseInt(sec / 60);
  if (m > 0) {
    sec -= m * 60;
  }

  let str = "";
  if (h > 0) str += h + " Jam ";
  if (m > 0) str += m + " Menit ";
  str += sec + " Detik";

  return str;
};
Number.prototype.formatDecimal = function () {
  return this.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};
const Redir = withRouter((props) => {
  console.log(props);
  useEffect(() => {
    setTimeout(() => {
      props.history.push("./login");
    }, 1000);
    return () => {};
  }, []);
  return <div>Redirecting...</div>;
});
function App(props) {
  const { href } = window.location;
  return (
    <Provider store={store}>
      <Router id="router">
        <Switch>
          <Route path="/" exact>
            <Redir />
          </Route>
          <Route path={"/main"}>
            <MainView />
          </Route>
          <Route path={"/login"}>
            <Login />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
