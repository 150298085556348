import React from "react";
import { Form, Input, Checkbox, Button, Icon, Typography, Select, Divider, Alert } from "antd";
import LoginImage from "../../assets/loginbg.jpg";
import "./Login.scss";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { TryLogin } from "../../actions/LoginAction";

const {} = Typography;
function Login(props) {
  /**
   * Hook UseSelector
   * Disini gua mau ngakses data global yang ada, nah ini ada hubungannya sama
   * file ./src/reducers/UserData.js TAPI jangan buka dulu. Liat file ini dulu ampe abis
   *
   *
   * KALO LU UDAH BALIK DARI REDUCER, baca, kalo belum, lanjut ke bawah
   *
   * Oke jadi di ./src/reducers/index.js lu liat entry yang namanya
   * ===> UserData
   * nah tadi gua sebut di reducer, kalo setiap reducer itu punya fungsi sebagai global
   * state nya. Nah sekarang perhatiin useSelector dibawah
   *
   */

  /**
   * KALO LU UDAH BALIK DARI REDUCER, baca, kalo belum, lanjut ke bawah
   * Kalo lu bingung soal const {user} , ini namanya object destructuring
   * simplenya sih, daripada nulis
   * const selector = useSelector(...)
   * const user = selector.user
   *
   * lebih baik const {user} = useSelector
   */
  const { user } = useSelector((store) => {
    /**
     * KALO LU UDAH BALIK DARI REDUCER, baca, kalo belum, lanjut ke bawah
     *
     * useSelector ini "nyambungin" komponen lu sekarang dengan global state.
     * dia nerima 1 parameter yaitu fungsi -> function(store){}
     * yang dia balikin adalah object dari storenya.
     *
     * Nah disini yang gua balikin adalah object UserData, masih inget?
     * store.UserData ini kemudian gua masukin ke key "user"
     * sehingga di komponen ini, gua ambil state UserData dengan nama User
     */
    return { user: store.UserData };
  });
  /**
   * KALO LU UDAH BALIK DARI REDUCER, baca, kalo belum, lanjut ke bawah
   *
   * Nah semua data yang ada di global state jadi bisa diliat di variable "user"
   */

  /**
   * KALO LU UDAH BALIK DARI REDUCER, baca, kalo belum, lanjut ke bawah
   *
   * Terus ini dispatchernya. Ketika lu mau ngakses data atau manipulasi data
   * di global state mau pake action mau ngga, tetep pake useDispatch
   *
   *
   * Dari sini sok ke JSX nya
   */
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
  };

  /**
   * Disini lu udah paham soal event handler kan? nah ketika tombol login di klik
   * gua manggil yang namanya dispatch. Itu diambil dari useDispatcher diatas.
   *
   * Apa yang gua kirim didalem fungsi dispatch adalah fungsi TryLogin, itu ada
   * di file ./src/actions/LoginAction.js
   *
   * Sekarang lu buka deh file ./src/actions/LoginAction.js
   */
  const handleLogin = (e) => {
    // props.history.push("/main/orders");
    dispatch(TryLogin(username, password));
  };

  /**
   * Oke in useEffect mungkin lu harus liat sendiri cara kerjanya gimana
   * tapi yang jelas, apa yang gua lakukan disini adalah
   *
   * 1. Gua ngecek perubahan dari variable "user" - yang mana tadi adalah hasil
   *    dari useSelector
   * 2. Ketika ada perubahan dari variable "user", maka...
   */
  useEffect(() => {
    /**
     *    ...gua akan ngecek userdatanya null atau tidak, dan auth_tokennya
     *    juga null atau tidak
     * 3. Jika ternyata tidak null, ga perlu login kan berarti? langsung
     *    masuk ke /main/orders.
     *
     * Sekarang buka ./src/Pages/Orders/Orders.js
     */
    console.log(user);
    if (user.userdata && user.userdata.auth_token) {
      props.history.push("/main/orders");
    }
    return () => {};
  }, [user]);

  return (
    <div className="login-background-layout">
      <div className="login-background-overlay" style={{ backgroundImage: `url(${LoginImage})` }}></div>
      <div className="login-background-overlay2"></div>
      <div className="welcome-text">
        <Typography.Title>Trackr Console</Typography.Title>
        <Typography.Paragraph style={{ fontSize: 17 }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde fuga a, commodi architecto modi optio veniam aperiam earum magnam laudantium blanditiis soluta officia quasi facilis, aut cumque
          eum? Ducimus, est.
        </Typography.Paragraph>
      </div>
      <div className="login-form">
        <Form onSubmit={handleSubmit} className="login-form">
          <h2>Trackr Console Login</h2>
          <Form.Item>
            <label htmlFor="">
              <strong>Perusahaan</strong>
            </label>
            <Select defaultValue={1}>
              <Select.Option value={1}>Trackr - SBE</Select.Option>
              {/* <Select.Option value={2}>PT Jawa Berkat Logistik</Select.Option>
              <Select.Option value={3}>PT Satria Putra Persada</Select.Option> */}
            </Select>
          </Form.Item>
          <Divider />
          {/* Masih inget di reducer tadi ada "error"-nya? */}
          {user?.error && (
            <React.Fragment>
              <Alert message={"Gagal Login!"} type="error" description={user.error} />
              <br />
            </React.Fragment>
          )}
          <Form.Item>
            <Input value={username} onChange={(e) => setUsername(e.target.value)} prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Username" />
          </Form.Item>
          <Form.Item>
            <Input value={password} onChange={(e) => setPassword(e.target.value)} prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />} type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Checkbox>Remember me</Checkbox>
            <br />
            <a className="login-form-forgot" href="">
              Forgot password
            </a>
            <br />
            {/* Masih inget tadi di reducer ada "loading"-nya? */}
            <Button disabled={user.loading} onClick={handleLogin} type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
            &nbsp;Or <a href="">register now!</a>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default withRouter(Login);
