import React, { useState } from "react";
import { Menu, Icon } from "antd";
import Logo from "../../../assets/trackrlogo.png";
import LogoNotext from "../../../assets/trackrlogo_notext.png";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CheckCircleFilled } from "@ant-design/icons";
// import { BrowserRouter as Router, useHistory } from "react-router-dom";

export default function SidebarContent(props) {
  /**
   * Yak lagi lagi ada useSelector
   * buat apa sih? si SidebarContent ini kan perlu nama user yang login
   * darimana kita dapetnya? dari global store dong
   *
   * Otomatis kita perlu selector sama persis seperti yang kita pake di
   * Login.js, cuman disini kita ngambil aja, ga ngapa ngapain, ga ngedispatch
   *
   * Liat ke bawah deh di JSX nya
   *
   */
  const { user } = useSelector((store) => ({ user: store.UserData }));
  const [selectedRoute, setSelectedRoute] = useState("/");
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="imgcontainer">
        <img src={Logo} />
      </div>
      <div className="userinfo">
        <div className="userimg">
          <img src={LogoNotext} alt="" />
        </div>
        <div className="user-detail">
          <span className="user-name">
            {/* 
            Disini gua ngambil user yang login. Perhatiin Reducer UserData kalo lu bingung
            Dari sini kita move ke yang agak lebih kompleks, masuk ke ./src/Pages/Main/Orders/CreateOrder/CreateOrder.js
             */}
            {user?.userdata?.firstname} {user?.userdata?.lastname}
          </span>
          <span className="user-info">Administrator</span>
        </div>
      </div>
      {/* <Router> */}
      <Menu
        style={{ flex: 1 }}
        onClick={(e) => {}}
        mode="vertical-left"
        theme="dark"
        // selectedKeys={selectedRoute}
      >
        <Menu.Item
          key="dashboard"
          onClick={(_) => {
            history.push("/main/orders");
          }}
        >
          <Icon type="project" />
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="trucks"
          onClick={(_) => {
            history.push("/main/trucks");
          }}
        >
          <Icon type="car" />
          Trucks
        </Menu.Item>
        <Menu.Item
          key="destinations"
          onClick={(_) => {
            history.push("/main/destinations");
          }}
        >
          <Icon type="environment" />
          Destinations
        </Menu.Item>
        <Menu.Item
          key="customers"
          onClick={(_) => {
            history.push("/main/customers");
          }}
        >
          <Icon type="contacts" />
          Customers
        </Menu.Item>
        <Menu.Item
          key="Maintenance Request"
          onClick={(_) => {
            history.push("/main/maintenance_request");
          }}
        >
          {/* <CheckCircleFilled /> */}
          <span>Maintenance Request</span>
        </Menu.Item>
        <Menu.Item
          key="Maintenance"
          onClick={(_) => {
            history.push("/main/maintenance");
          }}
        >
          {/* <CheckCircleFilled /> */}
          <span>Maintenance</span>
        </Menu.Item>
      </Menu>
      {/* </Router> */}
    </React.Fragment>
  );
}
