import { combineReducers } from "redux";
import Project from "./Project";
import BusinessGroup from "./BusinessGroup";
import Destination from "./Destination";
import Driver from "./Driver";
import Device from "./Device";
import Customer from "./Customer";
import TransactionType from "./TransactionType";
import Order from "./Orders";
import UserData from "./UserData";
import Province from "./Province";
import Regency from "./Regency";
import District from "./District";
import Village from "./Village";
import VehicleType from "./VehicleType";
import Mitra from "./Mitra";
import GeoSearch from "./GeoSearch";
import ReverseGeocoding from "./ReverseGeocoding";
import MaintenanceRequest from "./MaintenanceRequest";
import Sparepart from "./Sparepart";
import Mechanic from "./Mechanic";
import Maintenance from "./Maintenance";
/**
 * Satuin semua reducer
 * mungkin agak bingung, tapi sebenernya combineReducer ini
 * kalo lu tulis dengan syntak biasa, jadinya gini
 *
 * combineReducers({
 *    BusinessGroup:BusinessGroup,
 *    Project:Project,
 *    Destination:Destination,
 *    Driver:Driver,
 *    Device:Device,
 * })
 */
export const Reducers = combineReducers({
  BusinessGroup,
  Project,
  Destination,
  Driver,
  Device,
  Customer,
  TransactionType,
  Order,
  UserData,
  Province,
  Regency,
  District,
  Village,
  VehicleType,
  Mitra,
  GeoSearch,
  ReverseGeocoding,
  MaintenanceRequest,
  Sparepart,
  Mechanic,
  Maintenance,
});
