import React, { Suspense } from "react";
import { Skeleton } from "antd";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const Orders = React.lazy(() => import("./Orders/Orders"));
const Customers = React.lazy(() => import("./Customers/Customers"));
const Devices = React.lazy(() => import("./Devices/Devices"));
const Destinations = React.lazy(() => import("./Destinations/Destinations"));
const MaintenanceRequest = React.lazy(() =>
  import("./MaintenanceRequest/MaintenanceRequest")
);
const Maintenance = React.lazy(() => import("./Maintenance/Maintenance"));
export default function MainViewRouter(props) {
  const { path, params } = useRouteMatch();
  return (
    <Suspense fallback={<LoadingSkeleton />}>
      <Switch className="flex-bottom-router">
        <Route path={`${path}/orders`} key="orders">
          <Orders path="orders" />
        </Route>
        <Route path={`${path}/customers`} key="customers">
          <Customers />
        </Route>
        <Route path={`${path}/trucks`} key="trucks">
          <Devices />
        </Route>
        <Route path={`${path}/destinations`} key="destinations">
          <Destinations />
        </Route>
        <Route path={`${path}/maintenance_request`} key="destinations">
          <MaintenanceRequest />
        </Route>
        <Route path={`${path}/maintenance`} key="destinations">
          <Maintenance />
        </Route>
      </Switch>
    </Suspense>
  );
}

function SuspenseWrapper(props) {
  const Component = props.load;
  return (
    <Suspense fallback={<LoadingSkeleton />}>
      <Component {...props} />
    </Suspense>
  );
}

function LoadingSkeleton(props) {
  return (
    <div style={{ padding: 20 }}>
      <Skeleton paragraph={{ rows: 10, width: 300 }} active />
    </div>
  );
}
