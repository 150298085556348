let cachedData = [];
try {
  let data = JSON.parse(localStorage.getItem("TRACKRCONSOLE_CUSTOMERS"));
  if (data) {
    cachedData = data;
  }
} catch (error) {}
export default function Customer(
  state = {
    loading: false,
    customers: cachedData,
    error: null,
  },
  payload
) {
  switch (payload.type) {
    case "CUSTOMER_LOADING": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "CUSTOMER_LOADED": {
      localStorage.setItem(
        "TRACKRCONSOLE_CUSTOMERS",
        JSON.stringify(payload.data)
      );
      return {
        ...state,
        customers: payload.data,
        loading: false,
        error: false,
      };
    }
    case "CUSTOMER_LOAD_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case "CUSTOMER_SAVE": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "CUSTOMER_SAVE_OK": {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case "CUSTOMER_SAVE_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case "CUSTOMER_UPDATE": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "CUSTOMER_UPDATE_OK": {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case "CUSTOMER_UPDATE_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
