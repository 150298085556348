import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Reducers } from "./reducers";
import openSocket from "socket.io-client";
import Axios from "axios";

const store = createStore(Reducers, applyMiddleware(thunk));

// const trackrUrl = "http://interbiss-erp.net/trackr/application";
const socketUrl = "https://trackr-socket-notifier.herokuapp.com/";
const trackrUrl =
  process.env.REACT_APP_TRACKR_SERVERURL ||
  "https://trackr.id/internaldev/application";

let io = openSocket(socketUrl);
io.connect();
io.on("notify", (data) => {
  console.log("Socket Notified : ");
  console.log(data);
  if (data.msg ?? "" == "new_order_update") {
    /** Update order by receipt */
    if (!data.receipt) return;

    Axios.get(trackrUrl + `/api/get/order/${data.receipt}/info`)
      .then((resp) => {
        if (resp.status == 200 && resp.data) {
          store.dispatch({
            type: "ORDER_UPDATE_SINGLE",
            data: resp.data,
          });
        }
      })
      .catch((e) => {});
  }
});

export { trackrUrl, socketUrl };

export default store;
