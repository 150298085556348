import moment from "moment";
export default function Order(
  state = {
    loading: false,
    orders: [],
    error: null,
    timestamp: moment().valueOf(),
    lastNotificationReceipt: "",
    mapDisplay: null,
  },
  payload
) {
  /** Ensure every actions gets fired */
  state.timestamp = moment().valueOf();

  switch (payload.type) {
    case "ORDER_LOADING": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "ORDER_LOADED": {
      return {
        ...state,
        orders: payload.data,
        loading: false,
        error: false,
      };
    }
    case "ORDER_LOAD_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case "ORDER_UPDATE_SINGLE": {
      const { orders } = state;
      const { data } = payload;
      let searchIndex = -1;
      for (let i = 0; i < orders.length; i++) {
        if (Number(data.id) == Number(orders[i].id)) {
          searchIndex = i;
          break;
        }
      }
      if (searchIndex != -1) {
        orders[searchIndex] = payload.data;
      }

      return {
        ...state,
        lastNotificationReceipt: data.receipt,
      };
    }
    case "ORDER_CLEAR_NOTIFICATION": {
      return {
        ...state,
        lastNotificationReceipt: "",
      };
    }
    case "ORDER_DISPLAY_ON_MAP": {
      return {
        ...state,
        mapDisplay: payload.data,
      };
    }
    case "ORDER_SAVE": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "ORDER_SAVE_OK": {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case "ORDER_SAVE_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
