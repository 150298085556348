import Axios from "axios";
import { trackrUrl } from "../store";

/**
 * Ini fungsi TryLogin yang gua maksud.
 *
 * Nah lu perhatiin di fungsi ini gua NGEBALIKIN FUNGSI.
 * Perhatiin arrow function async (dispatch)=>{}
 * itu gua balikin fungsi
 *
 * Kalo misalnya lu melakukan hal yang sama tapi gagal, lu harus tau
 * gua pake yang namanya redux-thunk, FYI. Lu bisa buka di ./src/store.js
 *
 * Nah kita liat ya line per line
 *
 */
export function TryLogin(username, password) {
  return async (dispatch) => {
    /**
     * Disini gua ngirim sinyal dengan type "USERDATA_LOADING", maksudnya
     * gua akan ngirim sinyal ke global state bahwa gua ini lagi loading
     * userdata. NGIRIM SINYAL ya bukan ngubah state bahwa gua lagi loading
     */
    dispatch({
      type: "USERDATA_LOADING",
    });
    try {
      let resp = await Axios.post(trackrUrl + "/api/login", {
        username,
        password,
      });

      if (resp.status == 200) {
        /**
         * Blablabla ternyata login sukses. Nah disini gua ngirim sinyal USERDATA_LOADED
         * Disini gua ga cuma ngirim sinyal, tapi ngirim data yaitu di resp.data
         * maksudnya, gua ngirim sinyal USERDATA_LOADED disertai data yaitu hasil manggil API
         * dari trackr.
         */
        dispatch({
          type: "USERDATA_LOADED",
          data: resp.data,
        });
      }
    } catch (error) {
      if (error?.response?.status == 403) {
        /**
         * KALO error, disini gua ada 2 opsi, kalo lu pake Axios dan errornya dari server
         * lu bisa ambil error.response, artinya gua akan ngecek kalo status dari server itu 403
         * dengan cara if(error.response.status == 403)
         * Sedangkan tanda tanyanya sendiri lu boleh baca namanya
         *
         * babel plugin optional chaining operator
         *
         * disini kemudian gua ngirim sinyal yang namanya USERDATA_LOAD_FAIL
         * dengan disertakan alasan errornya apa.
         */
        dispatch({
          type: "USERDATA_LOAD_FAIL",
          error: "Data Username/Password yang diinput tidak terdaftar",
        });
      } else {
        /**
         * Nah ini kondisinya JIKA errornya bukan di server, entah internet si client atau apa
         * yang jelas gak sampe ke server. Dengan demikian lu tinggal ambil error.message nya aja
         *
         * Ini kira kira mencakup semua yang lu bisa kerjain di actions. Nah dari sini kita pindah ke
         * reducer. Sok buka ./src/reducers/UserData.js
         */
        dispatch({
          type: "USERDATA_LOAD_FAIL",
          error: error.message,
        });
      }
    }
  };
}
