let cachedData = [];
try {
  let data = JSON.parse(localStorage.getItem("TRACKRCONSOLE_MECHANIC"));
  if (data) {
    cachedData = data;
  }
} catch (error) {}

export default function Mechanic(
  state = {
    loading: false,
    data: cachedData,
    error: 0,
  },
  payload
) {
  switch (payload.type) {
    case "MECHANIC_LOADING": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "MECHANIC_LOADED": {
      localStorage.setItem(
        "TRACKRCONSOLE_MECHANIC",
        JSON.stringify(payload.data)
      );
      return {
        ...state,
        data: payload.data,
        loading: false,
        error: false,
      };
    }
    case "MECHANIC_LOAD_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
