import React, { Suspense } from "react";
import "./MainView.scss";
import SidebarContent from "./Components/SidebarContent";
import MainViewRouter from "./MainViewRouter";

export default function MainView(props) {
  return (
    <div className="app-layout animated">
      <div className="left-layout">
        <SidebarContent />
      </div>
      <div className="right-layout">
        <MainViewRouter />
      </div>
    </div>
  );
}
