/**
 * Helloo, Nah disini agak agak asing mungkin buat yang belum biasa buka JS
 *
 * Jadi disini kita bahas reducer, nah reducer itu apasih? reducer itu pada dasarnya
 * sebuah fungsi biasa, yang nerima 2 parameter, parameter pertama adalah STATE,
 * parameter kedua adalah PAYLOAD
 *
 * Disini, gua bikin sebuah fungsi yang namanya UserData, dimana parameter pertamanya
 * adalah state dan parameter keduanya adalah payload
 * kalo disederhanain, mungkin nama fungsinya akan jadi gini
 *
 *
 * function UserData(state,payload){}
 *
 * cuman, kenapa kok state-nya itu jadi state = {
 *    loading: false
 *    ...
 *    ...
 * }
 *
 * nah lu paham kan ketika state awal dibuat, dia akan NULL. Disini state awalnya akan
 * di defaultkan jadi data itu, jadi di aplikasi booting, state awalnya gak null...
 * Tanya gua lagi deh kalo lu gak paham
 *
 * Terus disini ada localstorage dll itu sebenernya ga penting sih, cuman berhubung ini
 * dipake buat login, jadi ketika loginnya berhasil gua save ke localStorage sementara
 * supaya ada cache, terus ketika di booting ini aplikasi akan baca localstorage
 * dulu supaya informasi loginnya tetep kesimpen
 *
 * Lu bisa liat reducer lain yang lebih sederhana kaya di project/bg deh kalo mau lebih enak
 *
 * Nah kita masuk ke line-per-line
 */

let cachedUserData = null;
try {
  cachedUserData = JSON.parse(localStorage.getItem("TRACKRCONSOLE_USERDATA"));
} catch (error) {}

/**
 * Disini udah gua jelasin ya, parameter state itu gua bikin default supaya ada STATE AWAL nya
 * ini ga cuma gua, tapi setiap orang yang pake redux pasti melakukan hal ini
 */
export default function UserData(
  state = {
    loading: false,
    userdata: cachedUserData,
    error: null
  },
  payload
) {
  /**
   * Oke terus kita ngapain?
   *
   * Disini kita proses SETIAP SINYAL yang dikirimkan melalui dispatcher atau action
   * kaya file sebelumnya yang lu buka.
   *
   * Jadi disini kita ngehandle semua yang terkait sama USERDATA, jadi semua sinyal yang masuk ke
   * CASE dibawah akan diproses disini. TAPI HANYA SINYAL TERTENTU YA.
   *
   * Maksudnya gini, setiap reducer yang disini akan kepanggil walaupun gak ada sinyalnya, jadi misal
   * gua manggil sinyal "PROJECT_LOADED", fungsi ini akan tetep kepanggil juga, cuman berhubung gak masuk
   * ke case manapun, dia gak akan ngubah statenya
   *
   * Oh iya, yang namanya reducer kaya yang 1 ini, dialah yang nanti akan kita ambil sebagai State nya.
   * Nanti kita balik lagi ke file login dan ke file-file lain yang pake reducer ini. Stay tuned
   */
  switch (payload.type) {
    /**
     * Disini sinyal USERDATA_LOADING diproses
     * perhatiin deh, disini gua ngirim object, isinya
     * state awal, loading:false, error:false
     *
     * PS: kalo lu bingung sama ...state, bisa cari di google
     * namanya ES6 Spread Operator
     *
     * Disini sebenernya yang gua lakuin itu, state awalnya dibiarkan
     * tapi loading dan errornya gua ubah
     *
     * Apa yang terjadi? setiap komponen yang terhubung ke state
     * ini akan nerima loading: true dan error: false
     * SEMUA.
     *
     * Gak cuma login, gak cuma sidebar, gak cuma order. SEMUA yang
     * terhubung.
     */
    case "USERDATA_LOADING": {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    /**
     * Disini proses "USERDATA_LOADED"
     * Mungkin lu masih inget ketika USERDATA_LOADED kita ga cuma ngirim sinyal,
     * tapi ada datanya juga
     */
    case "USERDATA_LOADED": {
      /**
       * Ketika sinyal dikirim, ada datanya kan
       * kalo lu lupa buka ./src/actions/LoginAction.js deh
       *
       * disini gua save dulu userdatanya ke localStorage
       */
      localStorage.setItem(
        "TRACKRCONSOLE_USERDATA",
        JSON.stringify(payload.data)
      );

      /**
       * Nah disini lu bisa liat, state yang gua ubah adalah
       * userdata : payload.data
       * loading : false
       * error : false
       *
       * Artinya setiap komponen yang terhubung akan dapet
       * userdata yang baru, status loading: false, dan
       * error: false
       */
      return {
        ...state,
        userdata: payload.data,
        loading: false,
        error: false
      };
    }
    /**
     * Nah disini kalo fail
     * disaat fail, yang gua ambil adalah errornya apa
     * jadi setiap komponen yang terhubung bisa akses ketika error kira kira
     * message nya apa...
     */
    case "USERDATA_LOAD_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    }
    /**
     * Ini perlu dap. PERLU.
     * yang namanya reducer itu gak boleh ngebalikin state kosong
     * jadi ketika tidak ada satupun sinyal yang masuk, lu balikin
     * state awalnya
     */
    default: {
      return state;
    }
  }
}

/**
 * Sampe sini dulu, masih inget kalimat
 * Disini kita proses SETIAP SINYAL yang dikirimkan melalui dispatcher atau action
 * Nah jadi Reducer ini (UserData) adalah satu dari sekian Reducer, dan semuanya
 * di combine jadi satu di ./src/reducers/index.js
 *
 * lu bisa cek fungsi combineReducer-nya.
 *
 * Dari sini kita balik lagi ke ./src/Pages/Login.js
 */
