let cachedData = [];
try {
  let data = JSON.parse(localStorage.getItem("TRACKRCONSOLE_DESTINATIONS"));
  if (data) {
    cachedData = data;
  }
} catch (error) {}

export default function Destination(
  state = {
    loading: false,
    destinations: cachedData,
    error: null,
  },
  payload
) {
  switch (payload.type) {
    case "DESTINATIONS_LOADING": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "DESTINATIONS_LOADED": {
      localStorage.setItem(
        "TRACKRCONSOLE_DESTINATIONS",
        JSON.stringify(payload.data)
      );
      return {
        ...state,
        destinations: payload.data,
        loading: false,
        error: false,
      };
    }
    case "DESTINATIONS_LOAD_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case "DESTINATION_SAVE": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "DESTINATION_SAVE_OK": {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case "DESTINATION_SAVE_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    case "DESTINATION_UPDATE": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "DESTINATION_UPDATE_OK": {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case "DESTINATION_UPDATE_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
