let cachedData = [];
try {
  let data = JSON.parse(localStorage.getItem("TRACKRCONSOLE_REGENCIES"));
  if (data) {
    cachedData = data;
  }
} catch (error) {}
export default function Regency(
  state = {
    loading: false,
    regencies: cachedData,
    error: null,
  },
  payload
) {
  switch (payload.type) {
    case "REGENCY_LOADING": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "REGENCY_LOADED": {
      localStorage.setItem(
        "TRACKRCONSOLE_REGENCIES",
        JSON.stringify(payload.data)
      );
      return {
        ...state,
        regencies: payload.data,
        loading: false,
        error: false,
      };
    }
    case "REGENCY_LOAD_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
