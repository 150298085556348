let deviceInStorage = [];

try {
  const data = JSON.parse(localStorage.getItem("TRACKRCONSOLE_DEVICES"));
  if (data) {
    deviceInStorage = data;
  }
} catch (error) {}

export default function Device(
  state = {
    loading: false,
    devices: deviceInStorage,
    error: null,
  },
  payload
) {
  switch (payload.type) {
    case "DEVICE_LOADING": {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case "DEVICE_LOADED": {
      localStorage.setItem(
        "TRACKRCONSOLE_DEVICES",
        JSON.stringify(payload.data)
      );
      return {
        ...state,
        devices: payload.data,
        loading: false,
        error: false,
      };
    }
    case "DEVICE_LOAD_FAIL": {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }
    default: {
      return state;
    }
  }
}
